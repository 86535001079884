const data_home = [
  {id:0, img: "../img/landscapes/sunriseinthedesert.jpeg", title:"Sunrise In The Desert"},
  {id:1, img: "../img/flowers/butterflyonsunflower.jpeg", title:"Butterfly On Sunflower"},
  {id:2, img: "../img/landscapes/sequoiacountry.jpeg", title:"Sequoia Country"},
  {id:3, img: "../img/flowers/sunflower.jpeg", title:"Sunflower"},
  {id:4, img: "../img/animals/troubleintheair.jpg", title:"Trouble In The Air"},
  {id:5, img: "../img/animals/familyouting.jpg", title:"Family Outing"},
  {id:6, img: "../img/animals/arabianfamily.jpg", title:"Arabian Family"},
  {id:7, img: "../img/flowers/orchids.jpg", title:"Orchids"},
  {id:8, img: "../img/landscapes/stormysail.jpg", title: "Stormy Sail"},
  {id:9, img: "../img/landscapes/lookingfortreasureonthebeach.jpg", title: "Looking For Treasure On The Beach"},
  {id:10, img: "../img/animals/kingofthehighcountry.jpg", title: "King Of The High Country"},
  {id:11, img: "../img/animals/resolvingconflict.jpg", title: "Resolving Conflict"},
  {id:12, img: "../img/people/soldier.jpg", title: "Soldier"},
  {id:13, img: "../img/flowers/katleyas2.jpg", title:"Katleyas 2"},
  {id:14, img: "../img/sculptures/girlwithbutterfly.jpg", title: "Girl With Butterfly"},
  {id:15, img: "../img/flowers/waterlilies.jpg", title:"Water Lilies"},
  {id:16, img: "../img/animals/blueberryfeast.jpg", title:'Blueberry Feast'},
  {id:17, img: "../img/landscapes/catalinasunset.jpg", title: "Catalina Sunset"},
  {id:18, img: "../img/people/girlwithorchids.jpg", title: "Girl With Orchids"}
];
const data_animals = [
  {id:0, img: "../img/animals/troubleintheair.jpg", title:"Trouble In The Air"},
  {id:1, img: "../img/animals/nightinalaska.jpg", title:"Night In Alaska"},
  {id:2, img: "../img/animals/familyouting.jpg", title:"Family Outing"},
  {id:3, img: "../img/animals/arabianfamily.jpg", title:"Arabian Family"},
  {id:4, img: "../img/animals/kingofthehighcountry.jpg", title:"King Of The High Country"},
  {id:5, img: "../img/animals/resolvingconflict.jpg", title:"Resolving Conflict"},
  {id:6, img: "../img/animals/fishinglesson.jpg", title:'Fishing Lesson'},
  {id:7, img: "../img/animals/patrollinghiskingdom.jpg", title:"Patrolling His Kingdom"},
  {id:8, img: "../img/animals/blueberryfeast.jpg", title:'Blueberry Feast'},
  {id:9, img: "../img/animals/arabianstallionintetons.jpg", title: "Arabian Stallion In Tetons"},
  {id:10, img: "../img/animals/alaskangrizzlyfamily.jpg", title:"Alaskan Grizzly Family"},
  {id:11, img: "../img/animals/morningouting.jpg", title:"Morning Outing"},
  {id:12, img: "../img/animals/grizzlypass.jpg", title:"Grizzly Pass"},
  {id:13, img: "../img/animals/tetonbison.jpg", title:"Teton Bison"},
  {id:14, img: "../img/animals/kingoftherockies.jpg", title:"King Of The Rockies"},
  {id:15, img: "../img/animals/bearplayground.jpg", title:'Bear Playground'},
  {id:16, img: "../img/animals/siberianromance.jpg", title:"Siberian Romance"},
  {id:17, img: "../img/animals/morningwakeupcall.jpg", title:"Morning Wakeup Call"},
  {id:18, img: "../img/animals/elklookout.jpg", title:"Elk Lookout"},
  {id:19, img: "../img/animals/bullelkonpatrol.jpg", title:"Bull Elk On Patrol"},
  {id:20, img: "../img/animals/lookingfortrouble.jpg", title:"Looking For Trouble"}
];
const data_landscapes = [
  {id:0, img: "../img/landscapes/sunriseinthedesert.jpeg", title: "Sunrise In The Desert"},
  {id:1, img: "../img/landscapes/sequoiacountry.jpeg", title: "Sequoia Country"},
  {id:2, img: "../img/landscapes/stormysail.jpg", title: "Stormy Sail"},
  {id:3, img: "../img/landscapes/lookingfortreasureonthebeach.jpg", title: "Looking For Treasure On The Beach"},
  {id:4, img: "../img/landscapes/autumninthealps.jpg", title: "Autumn In The Alps"},
  {id:5, img: "../img/landscapes/wartariver.jpg", title: "Warta River"},
  {id:6, img: "../img/landscapes/intherockies.jpg", title: "In The Rockies"},
  {id:7, img: "../img/landscapes/tetonsgold.jpg", title: "Tetons Gold"},
  {id:8, img: "../img/landscapes/taggertlake.jpg", title: "TaggertLake"},
  {id:9, img: "../img/landscapes/winterinthesierras.jpg", title: "Winter In The Sierras"},
  {id:10, img: "../img/landscapes/castlerock.jpg", title: "Castle Rock"},
  {id:11, img: "../img/landscapes/hawaiiansunset.jpg", title: "Hawaiian Sunset"},
  {id:12, img: "../img/landscapes/snakeriver.jpg", title: "Snake River"},
  {id:13, img: "../img/landscapes/autumnonoxbowbend.jpg", title: "Autumn on Oxbow Bend"},
  {id:14, img: "../img/landscapes/sunriseintetons.jpg", title: "Sunrise In The Tetons"},
  {id:15, img: "../img/landscapes/tetonroyalfamily.jpg", title: "Teton Royal Family"},
  {id:16, img: "../img/landscapes/solitudeinyosemite.jpg", title: "Solitude In Yosemite"},
  {id:17, img: "../img/landscapes/californiasunset.jpg", title: "California Sunset"},
  {id:18, img: "../img/landscapes/dohenybeach.jpg", title: "Doheny Beach"},
  {id:19, img: "../img/landscapes/catalinasunset.jpg", title: "Catalina Sunset"},
  {id:20, img: "../img/landscapes/majestyofsequoias.jpg", title: "Majesty of Sequoias"},
  {id:21, img: "../img/landscapes/patagonia.jpg", title: "Patagonia"},
  {id:22, img: "../img/landscapes/tetonsunrise.jpg", title:"Teton Sunrise"},
  {id:23, img: "../img/landscapes/alaskangold.jpg", title:"Alaskan Gold"},
  {id:24, img: "../img/landscapes/mountritter.jpg", title:"Mount Ritter"}
];
const data_flowers = [
  {id:0, img: "../img/flowers/sunflower.jpeg", title:"Sunflower"},
  {id:1, img: "../img/flowers/butterflyonsunflower.jpeg", title:"Butterfly On Sunflower"},
  {id:2, img: "../img/flowers/orchids.jpg", title:"Orchids"},
  {id:3, img: "../img/flowers/katleyas.jpg", title:"Katleyas"},
  {id:4, img: "../img/flowers/bloomingproteia.jpg", title:"Blooming Proteia"},
  {id:5, img: "../img/flowers/peonies.jpg", title:"Peonies"},
  {id:6, img: "../img/flowers/tropicalbouquet.jpg", title:"Tropical Bouquet"},
  {id:7, img: "../img/flowers/irises.jpg", title:"Irises"},
  {id:8, img: "../img/flowers/iristrio.jpg", title:"Iris Trio"},
  {id:9, img: "../img/flowers/dahlias.jpg", title:"Dahlias"},
  {id:10, img: "../img/flowers/waterlilies.jpg", title:"Water Lilies"},
  {id:11, img: "../img/flowers/feedingfrenzy.jpg", title:"Feeding Frenzy"},
  {id:12, img: "../img/flowers/hummingbirdonkatleyas.jpg", title:"Hummingbird on Katleyas"},
  {id:13, img: "../img/flowers/katleyas2.jpg", title:"Katleyas 2"},
  {id:14, img: "../img/flowers/redorientalpoppies.jpg", title:"Red Oriental Poppies"},
  {id:15, img: "../img/flowers/orangeorientalpoppies.jpg", title:"Orange Oriental Poppies"}
];
const data_people = [
  {id:0, img: "../img/people/soldier.jpg", title: "Soldier"},
  {id:1, img: "../img/people/ladyinred.jpg", title: "Lady In Red"},
  {id:2, img: "../img/people/girlwithorchids.jpg", title: "Girl With Orchids"},
  {id:3, img: "../img/people/reminiscing.jpg", title: "Reminiscing"},
  {id:4, img: "../img/people/onthebeach.jpg", title: "On The Beach"},
  {id:5, img: "../img/people/grandpa.jpg", title: "Grandpa"},
  {id:6, img: "../img/people/pickingirises.jpg", title: "Picking Irises"},
  {id:7, img: "../img/people/sadeyes.jpg", title: "Sad Eyes"},
  {id:8, img: "../img/people/huntingdiana.jpg", title: "Hunting Diana"},
  {id:9, img: "../img/people/coloring.jpg", title: "Coloring"},
  {id:10, img: "../img/people/enjoyingthesunshine.jpg", title: "Enjoying The Sunshine"},
  {id:11, img: "../img/people/krakowianka.jpg", title: "Krakowianka"},
  {id:12, img: "../img/people/onewithnature.jpg", title: "One With Nature"},
  {id:13, img: "../img/people/wateringflowers.jpg", title: "Watering Flowers"},

];
const data_sculptures = [
  {id:0, img: "../img/sculptures/girlwithbutterfly.jpg", title: "Girl With Butterfly"},
  {id:1, img: "../img/sculptures/beachball.jpg", title: "Beachball"},
  {id:2, img: "../img/sculptures/morningsplash.jpg", title: "Morning Splash"},
  {id:3, img: "../img/sculptures/youngbeauty.jpg", title: "Young Beauty"},
  {id:4, img: "../img/sculptures/playingfrisbee1.jpg", title: "Playing Frisbee 1"},
  {id:5, img: "../img/sculptures/playingfrisbee2.jpg", title: "Playing Frisbee 2"},
  {id:6, img: "../img/sculptures/windswept.jpg", title: "Windswept"},
  {id:7, img: "../img/sculptures/catchingthesun.jpg", title: "Catching The Sun"},
  {id:8, img: "../img/sculptures/catchingtheball.jpg", title: "Catching the Ball"},
  {id:9, img: "../img/sculptures/coolingoff.jpg",  title: "Cooling Off"},
  {id:10, img: "../img/sculptures/snowlady.jpg",  title: "Snowlady"}
];
const data_photos = [
  {id:0, img: "../img/photos/1.jpg", title: "1"},
  {id:1, img: "../img/photos/2.jpg", title: "2"},
  {id:2, img: "../img/photos/3.jpg", title: "3"},
  {id:3, img: "../img/photos/4.jpg", title: "4"},
  {id:4, img: "../img/photos/5.jpg", title: "5"},
  {id:5, img: "../img/photos/6.jpg", title: "6"},
  {id:6, img: "../img/photos/7.jpg", title: "7"},
  {id:7, img: "../img/photos/8.jpg", title: "8"},
  {id:8, img: "../img/photos/9.jpg", title: "9"},
  {id:9, img: "../img/photos/10.jpg",title: "10"},
  {id:10, img: "../img/photos/11.jpg", title: "11"},
  {id:11, img: "../img/photos/12.jpg", title: "12"},
  {id:12, img: "../img/photos/13.jpg", title: "13"},
  {id:13, img: "../img/photos/14.jpg", title: "14"},
  {id:14, img: "../img/photos/15.jpg", title: "15"},
  {id:15, img: "../img/photos/16.jpg", title: "16"},
  {id:16, img: "../img/photos/17.jpg", title: "17"},
  {id:17, img: "../img/photos/18.jpg", title: "18"},
  {id:18, img: "../img/photos/19.jpg", title: "19"},
  {id:19, img: "../img/photos/20.jpg", title: "20"},
  {id:20, img: "../img/photos/21.jpg", title: "21"},
  {id:21, img: "../img/photos/22.jpg", title: "22"},
  {id:22, img: "../img/photos/23.jpg", title: "23"},
  {id:23, img: "../img/photos/24.jpg", title: "24"},
  {id:24, img: "../img/photos/25.jpg", title: "25"}
]

export { data_home, data_animals, data_landscapes, data_flowers, data_people, data_sculptures, data_photos };
