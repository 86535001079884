import React from 'react'
import { Link } from 'react-router-dom'
import '../style.css'

const Header=()=>(
  <header>
    <div className="logo">
      Wojtekk Fine Art
    </div>
  </header>
)

export default Header
